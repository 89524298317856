// api/github.js
import axios from 'axios';

const authToken = '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'; // GitHub Kişisel Erişim Anahtarınız

export const fetchRepos = async (username) => {
  try {
    const response = await axios.get(`https://api.github.com/users/${username}/repos`, {
      headers: {
        'Authorization': `token ${authToken}`,
      },
    });
    return response.data; // Repo verilerini döndür
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Bir hata oluştu.'); // Hata mesajını döndür
  }
};
