import firebaseImg from '../img/techs/react.svg';
import nodejsImg from '../img/techs/node.svg';
import vueImg from '../img/techs/vue.png';
import htmlImg from '../img/techs/html.svg';
import javascriptImg from '../img/techs/javascript.svg';
import typescriptImg from '../img/techs/typescript.png';
import mysqlImg from '../img/techs/mysql.png';
import phpImg from '../img/techs/php.svg';
import electronjs from '../img/techs/electron.svg';
const Tech = () => {
    const techs = [
        ['React', firebaseImg],
        ['Node.js', nodejsImg],
        ['Vue.js', vueImg],
        ['Html', htmlImg],
        ['JavaScript', javascriptImg],
        ['TypeScript', typescriptImg],
        ['MySQL', mysqlImg],
        ['Php', phpImg],
        ['Electronjs', electronjs],
        // Buraya daha fazla teknoloji ekleyebilirsiniz
    ];

    return (
        
        <div className="w-full py-1 px-5">
                      <h1 style={{textAlign:'left',marginLeft:'7rem'}} className='text-4xl font-extrabold lg:text-start '><b>TECHNOLOGİES I USE</b></h1>
  
                <div className="gap-3 pt-12 w-full grid grid-cols-4 m:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
  {techs.map((tech, index) => (
    <div
      style={{
        position: 'relative',
        boxShadow: 'rgb(87 202 241 / 29%) 0px 4px 12px',
        marginLeft: '8rem', // 8rem sağa kaydırma
        gap:'10px'
      }}
      key={index}
      className="bg-[#ffffff61] hover:-translate-y-[3px] hover:bg-[#ffffff76] transition-all duration-200 rounded-lg p-2 flex items-center justify-between"
    >
      <img src={tech[1]} className="h-4x max-w-[25px]" alt={tech[0]} /> {/* Boyutları küçülttük */}
      <span className="text-lg">{tech[0]}</span> {/* Metni de küçülttük */}
    </div>
  ))}
</div>


            </div>
     
    );
};

export default Tech;
