
import '../App.css';
import Main from '../panel/main.jsx';
import Project from '../panel/project.jsx';
import Tech from '../panel/tech.jsx';
import Repos from '../panel/repos.jsx';
function App() {
  return (
    <div className="App">
      <div className="h-screen w-full flex items-center justify-center   to-gray-200">
      <Main />
      <div className=' lg:px-[3rem] md:px-[4rem] sm:px-[3rem] px-[2rem]'>
          <div className='pt-80' style={{paddingTop:'30rem',marginLeft:'-75rem' }}>
          <div>  
          <div className="flex items-center space-x-3">
          <div className="select-none w-10 h-10 flex items-center justify-center">
          </div> 
          </div>
            </div>
         <Tech />
          </div>
           </div>

           <div className=' lg:px-[3rem] md:px-[4rem] sm:px-[3rem] px-[2rem]'>
          <div className='' style={{paddingTop:'80rem',marginLeft:'-75rem' }}>
          <div>  
            
          <div className="flex items-center space-x-6">
      <h1  style={{ textAlign: 'left', marginLeft: '2rem', marginTop:'5rem' }} className="text-4xl font-extrabold lg:text-start">
  <b>SELECTED PROJECT</b>
</h1>
          <div className="select-none w-10 h-10 flex items-center justify-center">
            
          </div> 
          </div>
            </div>
         <Project /> 
         
          </div>
           </div>
        
          
           <div className=' lg:px-[3rem] md:px-[4rem] sm:px-[3rem] px-[2rem]'>
          <div className='' style={{paddingTop:'160rem',marginLeft:'-75rem' }}>
          <div>  
          <div className="flex items-center space-x-6">
          <div className="select-none w-10 h-10 flex items-center justify-center">
          </div> 
          </div>
            </div>
         <Repos /> 
          </div>
           </div>
    
        
            </div>
            <footer>
        <div  style={{marginTop:'90rem' , background:'none',marginLeft:'20px'}} className="footer mx-auto ">
          <p>
           Leawty &copy; 2024 All rights reserved. 
          </p>
        </div>
      </footer>
          </div>
      
         
    
   
  );
}

export default App;
