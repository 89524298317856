import ProjectCard from './ProjectCard'; // ProjectCard bileşenini içe aktar
import styles from './project.module.css'; // Proje için CSS modülünü içe aktar

const Project = () => {
  const projects = [
    {
      title: 'MPhosting',
      description: 'If your WordPress website is your personal business, we want you to succeed. A ton of website hosting options, 99.9% uptime guarantee, free SSL certificate, easy WordPress installs.',
      link: 'https://mphosting.shop/',
      backgroundImage: 'https://i.hizliresim.com/ckh8uxd.jpg',
    },
    {
      title: 'MPhosting',
      description: 'If your WordPress website is your personal business, we want you to succeed. A ton of website hosting options, 99.9% uptime guarantee, free SSL certificate, easy WordPress installs.',
      link: 'https://mphosting.shop/',
      backgroundImage: 'https://i.hizliresim.com/ckh8uxd.jpg',
    },
    {
      title: 'MPhosting',
      description: 'If your WordPress website is your personal business, we want you to succeed. A ton of website hosting options, 99.9% uptime guarantee, free SSL certificate, easy WordPress installs.',
      link: 'https://mphosting.shop/',
      backgroundImage: 'https://i.hizliresim.com/ckh8uxd.jpg',
    },
    // Diğer projeleri buraya ekleyebilirsiniz
  ];

  return (
    <div>
      <div style={{ position: 'relative', marginBottom: '10rem', zIndex: '1000' }}>
       <div id="projects-section" style={{ position: 'relative', marginBottom: '-10rem', zIndex: '1000' }}></div> <div className="select-none w-10 h-10 flex items-center justify-center"></div>
      </div>



      <div  className="max-w-screen-lg mx-auto w-full">
        {/* CSS Grid yapısını kullanarak projeleri gride oturtuyoruz */}
        <div style={{ textAlign: 'left', marginLeft: '-5rem' }} className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-5 mt-5">
          {projects.map((project, index) => (
            <ProjectCard key={index} project={project} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Project;
