import { useState, useEffect } from 'react';
import { fetchDiscordVerileri } from '../api/discordAPi.jsx';
import Projects from './project.jsx'; // Project.jsx dosyan
const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  const navbarHeight = document.querySelector('.nav').offsetHeight; // Navbar yüksekliği

  // Y kordinatını hesapla
  const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - navbarHeight;

  // Pürüzsüz kaydırma
  window.scrollTo({
    top: sectionPosition,
    behavior: 'smooth'
  });
};
const Main = () => {
  const [lanyardData, setLanyardData] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
      width: '100%',
      maxWidth: '500px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '15px 35px',
      borderRadius: '12px',
      backgroundColor: '#fff',
      boxShadow: isHovered ? '0 8px 20px rgba(144, 19, 254, 0.6)' : '0 4px 12px rgba(144, 19, 254, 0.3)', // Hover durumuna göre gölge
      border: `1px solid ${isHovered ? 'rgb(144, 19, 254)' : '#eaeaea'}`, // Hover durumuna göre kenarlık rengi
      transform: isHovered ? 'translateY(-5px)' : 'none', // Hover durumuna göre yukarı kaydır
      fontFamily: 'Arial, sans-serif',
      transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out, border-color 0.3s ease-in-out',
      position: 'relative'
  };

  // Inner button style
  const buttonInnerStyle = {
      display: 'flex',
      background: 'none',
      border: 'none',
      padding: '0',
      cursor: 'pointer',
      alignItems: 'center'
  };

  // Divider style
  const dividerStyle = {
      height: '20px',
      width: '1px',
      backgroundColor: '#dcdcdc',
      margin: '0 15px'
  };

  const handleClick = (section) => {
    setActiveSection(section);
  };
  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({
      behavior: 'smooth', // Pürüzsüz kaydırma
      block: 'start' // Üstten başlasın
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDiscordVerileri();
        setLanyardData(data);
      } catch (error) {
        console.error('Error fetching Discord data:', error);
      }
    };

    fetchData();
  }, []);

  // Aktivite bilgilerini al
  const activities = lanyardData?.data?.activities || [];

  // İlk aktivitenin "state" özelliğini al
  const firstActivityState = activities.length > 0 ? activities[0].state : null;

  // Discord durumlarını kontrol et
  const discordStatus = lanyardData?.data?.discord_status || '';
  const isUsername = lanyardData?.data?.discord_user?.username === 'memte.';

  return (
    <div style={{  width:'100%', display: 'flex', justifyContent: 'space-between', alignItems: 'left',marginRight:'-110px'}}>
      {lanyardData ? (
        <div style={{  margin:'auto', width: '65%' }}>
          <h1 style={{top:'-80px',position:'relative' ,textAlign:'left',fontSize: '60px', fontWeight: 'bold', fontFamily: 'MyCustomFont', color: '#111827' }}>
            Hi, I am <span style={{ color: '#2572be' }}>Leawty</span>
          </h1>
          <p style={{top:'-68px',position:'relative' , textAlign:'left' ,fontSize: '18px', fontWeight: '400', marginTop: '20px', color: '#4b5563' }}>
            Learning about web and trying to help out other devs in the process. 
            I love open source and writing about tech occasionally.
          </p>
          <p style={{top:'-48px', textAlign:'left' ,position:'relative' , fontSize: '18px', fontWeight: '400', marginTop: '10px', color: '#4b5563' }}>
            This is my place for <span className="highlight">thoughts, reflections & everything</span> in between. Have a good read!
          </p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
<div style={{top:'-30px', position: 'relative', zIndex: '0', marginRight: '0px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
    <div className="hover:-translate-y-[3px]"  style={buttonStyle}>
    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 -rotate-6 text-pink-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"></path>
        </svg>
      </div>
      <button onClick={() => scrollToSection('projects-section')} style={buttonInnerStyle}>
        <span style={{ color: '#a29bfe', fontWeight: 'bold' }}>Read my story!</span>
        <div style={dividerStyle}></div>
        <span style={{ color: '#2d3436', fontWeight: 'normal' }}>About →</span>
      </button>
    </div>
    <div style={buttonStyle}>
    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 -rotate-6 text-fuchsia-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
          <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path>
        </svg>
      </div>
      <button onClick={() => scrollToSection('projects-section')} style={buttonInnerStyle}>
        <span style={{ color: '#ff7675', fontWeight: 'bold' }}>What I built!</span>
        <div style={dividerStyle}></div>
        <span style={{ color: '#2d3436', fontWeight: 'normal' }}>Projects →</span>
      </button>
    </div>
    
    <div style={buttonStyle}>
    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 -rotate-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"></path>
        </svg>
      </div>
      <button onClick={() => scrollToSection('projects-section')} style={buttonInnerStyle}>
        <span style={{ color: '#6c5ce7', fontWeight: 'bold', gap:'20px' }}>Hire me!</span>
        <div style={dividerStyle}></div>
        <span style={{ color: '#2d3436', fontWeight: 'normal' }}>Resume →</span>
      </button>
    </div>
    
    {/* Diğer butonlar */}
  </div>



      <style>{`
        .highlight {
          background-color: yellow;
          padding: 0 5px;
          box-shadow: 0 2px 0 yellow;
          transition: background-color 0.3s ease;
        }
        .highlight:hover {
          background-color: rgba(255, 255, 0, 0.7);
          box-shadow: 0 2px 0 rgba(255, 255, 0, 0.7);
        }
      `}</style>
    </div>
  );
};

export default Main;
