import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {Routes, Route, BrowserRouter,} from "react-router-dom";
import Header from './panel/header';
import Home from './pages/Home';
import About from './pages/About';
import Project from "./pages/project";

function App() {
        
    ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
<BrowserRouter>  
    <Header />
  <Routes>
    <Route path="/home/*" element={<Home />} />
    <Route path="/" element={<Home />} />
    <Route path="/about/*" element={<About />} />
    <Route path="/project/*" element={<Project />} />
  </Routes>
</BrowserRouter>
  </React.StrictMode>

); 
 return (
    <div className="App">

          </div>     
  );
}

export default App;
