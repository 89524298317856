import React from 'react'
import '../style/about.css';
const about = () => {
  return (
    <div>
   <div className="container">
      <div className="left-content">
        <div className="image-container">
          <img src="https://slyro.vercel.app/_next/image?url=https%3A%2F%2Favatars.githubusercontent.com%2Fu%2F122960451&w=256&q=75" alt="Açıklama" className="image" />
          <div className="overlay-text">About</div>
        </div>
        <h1 className="image-title">Leawty</h1>
        <p className="image-text">Web Developer<br></br> Student</p>
      </div>

      <div className="right-content">
      <section className="section curly-brace-effect">
  <p>
    I'm Dev, a 18-year-old student belonging to the Homo Sapiens species. I'm a brother, a son, a friend, a self-taught web developer and programmer, an open-source enthusiast, and an extreme melophile.
  </p>
</section>

        <section className="section">
          <h2>Bölüm 2</h2>
          <p>
            Bu, bölüm 2'deki paragraf metnidir. Duis aute irure dolor in reprehenderit 
            in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          </p>
        </section>
        <section className="section">
          <h2>Bölüm 2</h2>
          <p>
            Bu, bölüm 2'deki paragraf metnidir. Duis aute irure dolor in reprehenderit 
            in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          </p>
        </section>
        <section className="section">
          <h2>Bölüm 2</h2>
          <p>
            Bu, bölüm 2'deki paragraf metnidir. Duis aute irure dolor in reprehenderit 
            in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          </p>
        </section>
        <section className="section">
          <h2>Bölüm 3</h2>
          <p>
            Bu, bölüm 3'teki paragraf metnidir. Excepteur sint occaecat cupidatat 
            non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </section>
      </div>
    </div>
    </div>
  )
}

export default about
