import { useEffect, useState } from 'react';
import { fetchRepos } from '../api/reposAPi'; // API modülünü içe aktar
const RepoList = () => {
  const [repos, setRepos] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadRepos = async () => {
      try {
        const data = await fetchRepos('memte'); // Kullanıcı adını ver
        setRepos(data); // Repo verilerini state'e kaydet
      } catch (error) {
        setError(error.message); // Hata mesajını state'e kaydet
      } finally {
        setLoading(false); // Yükleme durumu
      }
    };

    loadRepos(); // Repos verilerini yükle
  }, []);

  if (loading) return <div>Loading...</div>; // Yükleniyor durumu
  if (error) return <div>Error: {error}</div>; // Hata mesajı


    return (
        
        <div className="w-full py-6">
                      <h1 style={{marginBottom:'15px',textAlign:'left',marginLeft:'-4rem'}} className='text-4xl font-extrabold lg:text-start '><b>GİTHUB REPOS</b>  </h1>
                      <div style={{ marginBottom: '0rem',  }}>
            <div className="max-w-screen-lg mx-auto w-full">
           
                <ul style={{marginBottom:'-10rem'}}>
          {repos.map((repo) => (
            <li 
              key={repo.id} 
              className="p-4 rounded-lg hover:bg-gray-300 transition duration-200" 
              style={{ background: 'transparent' }}
            >
              <div style={{   padding: '1rem',   transition: 'transform 0.3s ease',  position:'relative', marginLeft: '-8rem',textAlign:'left',}} className="hover:transform hover:scale-105 hover:rounded-2xl hover:bg-gray-100 relative">
                <p style={{color:'#6B7280', fontSize:'14px'}} className=" text-sm">{new Date(repo.created_at).toLocaleDateString()}</p>
                <h2 style={{color:'#111827', fontSize:'28px', fontWeight:'700', }} className="mt-1  font-semibold">{repo.name}</h2>
                <span style={{ backgroundColor:'#fff', color:'#6c63ff', border:'1px solid #6c63ff',borderRadius:'6px', fontSize:'13px'}} className="inline-block bg-blue-500 text-white  font-semibold rounded-full px-3 py-1 mt-2">
                  {repo.language || 'N/A'}
                </span>
                <p style={{fontSize:'15px'}} className="text-gray-500 mt-2 ">{repo.description || 'No description available.'}</p>
              </div>
            </li>
          ))}
        </ul>

            </div>
            </div> </div>
    );
};

export default RepoList;
