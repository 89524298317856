
import '../App.css';
import About from '../panel/about.jsx';
function App() {
  return (
    <div className="App">
      <div className="h-screen w-full flex items-center justify-center   to-gray-200">
      <About />
    </div>
    <footer>
        <div  style={{marginTop:'13rem' , background:'none',marginLeft:'20px'}} className="footer mx-auto ">
          <p>
           Leawty &copy; 2024 All rights reserved. 
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
