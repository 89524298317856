import '../App.css';
import Project from '../panel/project.jsx';

function App() {
  return (
    <div className="App">
      <div className='h-[100vh] w-full flex flex-col'>
        <div className='lg:px-[3rem] md:px-[4rem] sm:px-[3rem] px-[2rem] w-full'>
          <p className='text-4xl font-semibold lg:text-start' style={{ marginTop: '150px' }}><b>Projects</b></p>
          <p style={{marginRight:'50rem'}} className='font-semibold lg:text-start mt-3'>⚙️ Projects I've been to, developed and am developing.</p>
        </div> 
        <div className='w-full mt-5'>
          <Project />
        </div>
      </div>
      <footer>
        <div  style={{marginTop:'0rem' , background:'none',}} className="footer mx-auto ">
          <p>
           Leawty &copy; 2024 All rights reserved. 
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
